import React from 'react';
import { observer, inject } from 'mobx-react';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import { observable, action, makeObservable } from 'mobx';
import parse from 'html-react-parser';

import Image from '../../Image';
import { Sizes } from '../../../constants/Image';

import TranslationsStore from '../../../stores/Translations';
import BannersStore, { BannerComponent } from '../../../stores/Banners';

import OmnivaSVG from '../../../images/omniva.svg';
import SmartPostiSVG from '../../../images/smartposti.svg';

import './styles/index.scss';

interface Props {
  banners?: BannersStore;
  translations?: TranslationsStore;
}

@inject('banners', 'translations')
@observer
export default class ReturnPage extends React.Component<Props> {
  @observable banners = [];

  constructor(props: Props) {
    super(props);
    makeObservable(this);
  }

  public componentDidMount() {
    this.loadBanners();
  }

  async loadBanners() {
    const { banners } = this.props;
    const bannerImages = await banners.loadByComponent(BannerComponent.RETURNS);
    this.setBanners(bannerImages);
  }

  @action setBanners(banners) {
    this.banners = banners;
  }

  shippingBoxes() {
    const shippingBoxes = this.banners
      .filter((banner) => banner.collection === 'boxes')
      .map((banner, index) => {
        return (
          <div className="info-box" key={index}>
            <div className="logo-container">
              <Image
                className={'info-box-logo'}
                image={banner.cover}
                defaultSize={Sizes.W100}
              />
            </div>
            <p className="info-box-title">{parse(banner.content)}</p>
          </div>
        );
      });

    return shippingBoxes;
  }

  returnBox() {
    // const { translations } = this.props;

    const shippingBoxes = this.banners
      .filter((banner) => banner.collection === 'return-box')
      .map((banner, index) => {
        // const file = translations.language === 'et' ? 'tagastus.pdf' : 'tagastus_rus.pdf';
        // const pdfUrl = `https://api.rademar.ee/feeds/${file}`;

        return (
          <div className="return-box" key={index}>
            <div className="return-box-title">{parse(banner.content)}</div>
            {/* <div className="button">
                            <a target="_blank" href={pdfUrl}>{banner.button}</a>
                        </div> */}
          </div>
        );
      });

    return shippingBoxes;
  }

  shippingContent() {
    const shippingContent = this.banners
      .filter((banner) => banner.collection === 'shipping')
      .map((banner, index) => {
        return (
          <TabPanel key={index}>
            <div className="tab-content">{parse(banner.content)}</div>
          </TabPanel>
        );
      });

    return shippingContent;
  }

  render() {
    return (
      <div className="component-return">
        <div className="wrap">
          <div className="boxes">
            {this.returnBox()}
            <div className="info">{this.shippingBoxes()}</div>
          </div>
          <div className="tabs">
            <Tabs className="tabs-menu">
              <TabList className="tabs-list">
                <Tab>
                  <div className="tab">
                    <img src={SmartPostiSVG} alt="" className="tab-img" />
                  </div>
                </Tab>
                <Tab>
                  <div className="tab">
                    <img src={OmnivaSVG} alt="" className="tab-img" />
                  </div>
                </Tab>
              </TabList>
              {this.shippingContent()}
            </Tabs>
          </div>
        </div>
      </div>
    );
  }
}
